<template>
  <base-view icon="folder" title="Unidades administrativas">
    <!-- Muestra el periodo fiscal activo -->
    <template #right-place>
      <div class="row">
        <x-periodo-fiscal-tag classBody="col-4 offset-8 right-content" variant="primary" />
      </div>
    </template>

    <check-authorization
      :requiresAuthorizations="['crear unidades administrativas']"
      :overrideIf="$store.getters['administrativeUnitModule/isEditingAdministrativeUnit']"
    >
      <administrative-unit-form
        ref="administrativeUnitForm"
        @hideForm="showForm = false"
        v-show="showForm || isEditingAdministrativeUnit"
      />
    </check-authorization>

    <check-authorization :requiresAuthorizations="['listar unidades administrativas']">
      <div class="card card-header-actions">
        <div class="card-header">
          Listado de unidades administrativas
          <!-- Search -->
          <administrative-unit-search class="ml-auto"></administrative-unit-search>
          <button v-show="!showForm" class="btn btn-success btn-sm ml-2" @click="cancel()">
            {{ !showForm ? 'Crear unidad administrativa' : 'Ocultar' }}
          </button>
          <b-dropdown id="dropdown-1" text="Exportar" class="m-md-2" size="sm">
            <b-dropdown-item @click="download('xls')">XLS</b-dropdown-item>
            <b-dropdown-item @click="download('pdf')">PDF</b-dropdown-item>
          </b-dropdown>
        </div>

        <!-- Contenedor -->
        <div class="card-body">
          <administrative-unit-list @hideForm="showForm = true" @input="onChangePeriodoFiscal"></administrative-unit-list>
        </div>
      </div>
    </check-authorization>
  </base-view>
</template>

<script>
import { mapGetters } from 'vuex'
import AdministrativeUnitForm from '@/components/AdministrativeUnits/AdministrativeUnitForm'
import AdministrativeUnitList from '@/components/AdministrativeUnits/AdministrativeUnitList'
import AdministrativeUnitSearch from '@/components/AdministrativeUnits/AdministrativeUnitSearch'

export default {
  name: 'AdministrativeUnits',

  components: {
    AdministrativeUnitForm,
    AdministrativeUnitList,
    AdministrativeUnitSearch
  },

  computed: {
    ...mapGetters('administrativeUnitModule', [
      'isEditingAdministrativeUnit',
      'hasAdministrativeUnits'
    ])
  },

  data () {
    return {
      showForm: false,

      periodo_id: null
    }
  },

  methods: {
    cancel () {
      this.showForm = !this.showForm
    },

    onChangePeriodoFiscal (value) {
      this.periodo_id = value
    },

    async download (formato) {
      const getval = await this.$store.dispatch('administrativeUnitModule/export', {
        periodo_id: this.periodo_id,
        formato: formato
      })

      await this.$onDownload(getval, `unidades_administrativas.${formato}`)

      this.$notify({ message: 'Listado de unidades administrativas generado' })
    }
  }
}
</script>
